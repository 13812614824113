var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "mb-1",
    attrs: {
      "id": "sale-report-f2-filters",
      "no-body": ""
    }
  }, [_c('b-card-body', {
    staticClass: "py-1"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-class": "h5 py-0",
      "label": _vm.$t('topup.startDate')
    }
  }, [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "placeholder": _vm.$t('topup.placeholderSelectDate'),
      "value": _vm.startDateFilter,
      "config": {
        allowInput: true,
        dateFormat: 'Y-m-d',
        altFormat: 'd/m/Y',
        altInput: true,
        maxDate: _vm.endDateFilter ? _vm.endDateFilter : _vm.today,
        locale: this.$i18n.locale === 'vi' ? _vm.Vietnamese : null,
        disableMobile: true
      }
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('update:start-date-filter', $event);
      }
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-class": "h5 py-0",
      "label": _vm.$t('topup.endDate')
    }
  }, [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "value": _vm.endDateFilter,
      "placeholder": _vm.$t('topup.placeholderSelectDate'),
      "config": {
        allowInput: true,
        dateFormat: 'Y-m-d',
        altFormat: 'd/m/Y',
        altInput: true,
        minDate: _vm.startDateFilter,
        maxDate: _vm.today,
        locale: this.$i18n.locale === 'vi' ? _vm.Vietnamese : null,
        disableMobile: true
      }
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('update:end-date-filter', $event);
      }
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-class": "h5 py-0"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('agency.agencyCode')) + " "), _c('span', {
          staticClass: "text-danger"
        }, [_vm._v("(*)")])];
      },
      proxy: true
    }])
  }, [_c('v-select', {
    attrs: {
      "id": "agencyCode",
      "value": _vm.agencyFilter,
      "options": _vm.agencyOptions,
      "label": "agencyCode",
      "searchabled": "",
      "clearable": "",
      "filterable": false,
      "loading": _vm.loading,
      "placeholder": _vm.$t('saleReport.phAgencyCode'),
      "reduce": function reduce(val) {
        return val;
      }
    },
    on: {
      "open": _vm.handleOpenAgency,
      "search": _vm.handleSearchAgency,
      "input": function input(val) {
        return _vm.$emit('update:agency-filter', val);
      }
    },
    scopedSlots: _vm._u([{
      key: "selected-option",
      fn: function fn(_ref) {
        var agencyName = _ref.agencyName,
          agencyCode = _ref.agencyCode;
        return [_c('div', {}, [_c('span', {
          staticClass: "d-block font-weight-bold text-truncate text-uppercase"
        }, [_c('span', {
          staticClass: "text-info"
        }, [_vm._v(_vm._s(agencyCode))]), _vm._v(" "), _c('small', [_vm._v("(" + _vm._s(agencyName) + ")")])])])];
      }
    }, {
      key: "option",
      fn: function fn(_ref2) {
        var agencyName = _ref2.agencyName,
          agencyCode = _ref2.agencyCode;
        return [_c('div', {}, [_c('span', {
          staticClass: "d-block font-weight-bold text-truncate text-uppercase"
        }, [_c('span', {}, [_vm._v(_vm._s(agencyCode))]), _vm._v(" "), _c('small', [_vm._v("(" + _vm._s(agencyName) + ")")])])])];
      }
    }, {
      key: "spinner",
      fn: function fn() {
        return [_vm.loading ? _c('div', {
          staticClass: "vs__spinner",
          staticStyle: {
            "border-left-color": "rgba(88, 151, 251, 0.71)"
          }
        }) : _vm._e()];
      },
      proxy: true
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-class": "h5 py-0",
      "label": _vm.$t('saleReport.columns.bookingCode')
    }
  }, [_c('b-form-input', {
    attrs: {
      "value": _vm.bookingCodeFilter,
      "placeholder": _vm.$t('saleReport.phBookingCode'),
      "formatter": _vm.trimUpperCaseInput
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('update:booking-code-filter', $event);
      }
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-class": "h5 py-0",
      "label": _vm.$t('saleReport.columns.flightType')
    }
  }, [_c('v-select', {
    staticClass: "w-100",
    staticStyle: {
      "font-size": "1rem"
    },
    attrs: {
      "value": _vm.flightTypeFilter,
      "options": _vm.flightTypeOptions,
      "label": "label",
      "clearable": false,
      "placeholder": _vm.$t('placeholderSelect'),
      "reduce": function reduce(val) {
        return val.value;
      }
    },
    on: {
      "input": function input(val) {
        return _vm.$emit('update:flight-type-filter', val);
      }
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(_vm.$t(data.label)) + " ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(_vm.$t(data.label)) + " ")])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-class": "h5 py-0",
      "label": _vm.$t('saleReport.flightDate')
    }
  }, [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "value": _vm.flightDateFilter,
      "placeholder": _vm.$t('topup.placeholderSelectDate'),
      "config": {
        allowInput: true,
        dateFormat: 'd/m/Y',
        altFormat: 'd/m/Y',
        altInput: true,
        locale: this.$i18n.locale === 'vi' ? _vm.Vietnamese : null,
        disableMobile: true
      }
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('update:flight-date-filter', $event);
      }
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-class": "h5 py-0",
      "label": _vm.$t('saleReport.columns.source')
    }
  }, [_c('v-select', {
    staticClass: "w-100",
    staticStyle: {
      "font-size": "1rem"
    },
    attrs: {
      "value": _vm.sourceFilter,
      "options": _vm.distributorsList,
      "label": "label",
      "clearable": "",
      "placeholder": _vm.$t('placeholderSelect'),
      "reduce": function reduce(val) {
        return val.value;
      }
    },
    on: {
      "input": function input(val) {
        return _vm.$emit('update:source-filter', val);
      }
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(_vm._s(_vm.$te("flight.airlines.".concat(data.label)) ? "".concat(_vm.$t("flight.airlines.".concat(data.label)), " (").concat(_vm.$t("flight.sourceName.".concat(data.label)), ")") : data.label))])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(_vm._s(_vm.$te("flight.airlines.".concat(data.label)) ? "".concat(_vm.$t("flight.airlines.".concat(data.label)), " (").concat(_vm.$t("flight.sourceName.".concat(data.label)), ")") : data.label))])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-class": "h5 py-0",
      "label": _vm.$t('saleReport.columns.ticketType')
    }
  }, [_c('v-select', {
    staticClass: "w-100",
    staticStyle: {
      "font-size": "1rem"
    },
    attrs: {
      "value": _vm.saleReportTicketTypeFilter,
      "options": _vm.saleReportTicketTypeOptions,
      "label": "label",
      "clearable": "",
      "placeholder": _vm.$t('placeholderSelect'),
      "reduce": function reduce(val) {
        return val.value;
      }
    },
    on: {
      "input": function input(val) {
        return _vm.$emit('update:sale-report-ticket-type-filter', val);
      }
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(_vm.$t(data.label)) + " ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(_vm.$t(data.label)) + " ")])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-class": "h5 py-0",
      "label": _vm.$t('saleReport.columns.airline')
    }
  }, [_c('b-form-input', {
    attrs: {
      "value": _vm.airlineFilter,
      "placeholder": _vm.$t('saleReport.phAirline'),
      "formatter": _vm.trimUpperCaseInput,
      "maxlength": "2"
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('update:airline-filter', $event);
      }
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "d-flex-center justify-content-end flex-1 gap-2",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('b-button', {
    attrs: {
      "size": "sm",
      "variant": "warning",
      "disabled": !_vm.agencyFilter
    },
    on: {
      "click": _vm.onUpdateFilters
    }
  }, [_c('HStack', {
    staticClass: "text-nowrap",
    attrs: {
      "center": ""
    }
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "icon": "SearchIcon",
      "size": "16"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('search')) + " ")], 1)], 1), _c('b-button', {
    attrs: {
      "variant": "danger",
      "size": "sm"
    },
    on: {
      "click": _vm.onClearFilters
    }
  }, [_c('HStack', {
    staticClass: "text-nowrap",
    attrs: {
      "center": ""
    }
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "icon": "XOctagonIcon",
      "size": "16"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('clear')) + " ")], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }