<template>
  <div id="sale-report-index">
    <b-tabs
      v-if="roleMama"
      pills
    >
      <b-tab active>
        <template #title>
          <span>{{ $t('saleReport.title') }}</span>
        </template>
        <SaleReportList />
      </b-tab>

      <b-tab>
        <template #title>
          <span>{{ $t('saleReport.f2.title') }}</span>
        </template>
        <SaleReportF2List />
      </b-tab>
    </b-tabs>
    <SaleReportList v-else />
  </div>
</template>

<script>
import {
  BTab, BTabs,
} from 'bootstrap-vue'
import { computed } from '@vue/composition-api'

import store from '@/store'

import SaleReportList from '@saleReport/SaleReportList.vue'
import SaleReportF2List from '@saleReport/sale-report-f2/SaleReportF2List.vue'

export default {
  components: {
    BTab,
    BTabs,
    SaleReportList,
    SaleReportF2List,
  },
  setup() {
    const roleMama = computed(() => store.getters['userStore/getRoleMama'])
    return {
      roleMama,
    }
  },
}
</script>

<style lang="scss" scoped>
#sale-report-index ::v-deep {
  ul.nav.nav-pills {
    margin-bottom: 0.5rem;
  }
}

</style>
